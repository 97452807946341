// import React, { useEffect, useRef, useState } from "react";
// import {
//   Card,
//   Button,
//   CardTitle,
//   CardText,
//   Row,
//   Col,
//   CardImg,
// } from "reactstrap";
// import { GrMailOption, GrPhone, GrLocation } from "react-icons/gr";

// const Landing = () => {
 

//   return (
  

//           <Card body  style={{ margin: "50px", padding:"50px",backgroundColor:"#7E1757" }}>
//             <h2  className="Header" >
//             ATO ENERGY
//             </h2>
//             <p className="subtitle">Add capacity!</p>
//             <CardText className="mt-5" style={{color:"white"}}>
//               <Row>
//                 <Col sm="2" md="2" lg="1">
//                   <GrLocation size={25} />
//                 </Col>
//                 <Col sm="10" md="10" lg="11">
//                   Östermalmsgatan 26A, 114 26 Stockholm
//                 </Col>
//               </Row>
//             </CardText>

//             <CardText className="mt-4" style={{color:"white"}}>
//               <Row>
//                 <Col sm="2" md="2" lg="1">
//                   <GrPhone size={25} />
//                 </Col>
//                 <Col sm="10" md="10" lg="11">
//                   +46 709 24 52 96
//                 </Col>
//               </Row>
//             </CardText>

//             <CardText className="mt-4" style={{color:"white"}}>
//               <Row>
//                 <Col sm="2" md="2" lg="1">
//                   <GrMailOption size={25} />
//                 </Col>
//                 <Col sm="10" md="10" lg="11">
//                   <a href="mailto:info@dtr1tech.com" style={{color:"white"}}> info@ato.energy</a>
//                 </Col>
//               </Row>
//             </CardText>
//           </Card>
        
        
      

//   );
// };

// export default Landing;

import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  CardImg,
} from "reactstrap";
import { GrMailOption, GrPhone, GrLocation } from "react-icons/gr";

const Landing = () => {
 

  return (
  <Row   style={{ height:"100vh",padding:"100px",backgroundColor:"#7E1757" }}>
    <Col sm="6">
          <Card body  style={{ padding:"82px",backgroundColor:"rgba(255, 255, 255, 0.8)" }}>
            <h2  className="Header" >
            ATO ENERGY
            </h2>
            <p className="subtitle">Add capacity!</p>
            <CardText className="mt-5" >
              <Row>
                <Col sm="2" md="2" lg="1">
                  <GrLocation size={25} />
                </Col>
                <Col sm="10" md="10" lg="11">
                  Östermalmsgatan 26A, 114 26 Stockholm
                </Col>
              </Row>
            </CardText>

            <CardText className="mt-4" >
              <Row>
                <Col sm="2" md="2" lg="1">
                  <GrPhone size={25} />
                </Col>
                <Col sm="10" md="10" lg="11">
                  +46 709 24 52 96
                </Col>
              </Row>
            </CardText>

            <CardText className="mt-4" >
              <Row>
                <Col sm="2" md="2" lg="1">
                  <GrMailOption size={25} />
                </Col>
                <Col sm="10" md="10" lg="11">
                  <a href="mailto:info@dtr1tech.com" > info@ato.energy</a>
                </Col>
              </Row>
            </CardText>
          </Card>
        </Col>
        <Col sm="6">
        <Card body style={{ border: "none" }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2034.344381033928!2d18.067293161025002!3d59.34389904390259!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9eed05efbb67%3A0xc18df40fa1b3a763!2sSting%20%E2%80%93%20Stockholm%20Innovation%20%26%20Growth%20AB!5e0!3m2!1sen!2sse!4v1698225679555!5m2!1sen!2sse"
            width="100%"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Card>
      </Col>
      
</Row>
  );
};

export default Landing;
