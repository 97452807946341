// src/App.js
import React from 'react';
import Header from './components/Header';
import About from './components/About';
import Footer from './components/Footer';
import './App.css';
import Landing from './components/Landing';
function App() {
  return (
    <div>
       {/* <Header />
      <About/>
      <Footer />  */}
      <Landing/>
    </div>
  );
}

export default App;
